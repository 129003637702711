<template>
  <div class="medic-pagin flex-start-center" v-if="total!==0">
    <!-- <div
      class="total"
      v-if="showTotal"
    >
      共<span class="size">{{total}}</span>个项目
    </div>
    <div class="page flex-start-center">
      <div
        class="item"
        :class="{act: activeIndex == item}"
        v-for="item in pageNum"
        :key="item"
        @click="changeSize(item)"
      >{{item}}</div>
    </div>
    <div
      class="next"
      @click="nextPage"
    >下一页</div> -->
    <el-pagination
      background
      @current-change="changeSize"
      :current-page="activeIndex"
      :pager-count="pagerCount"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    pagerCount: {
      type: Number,
      default: 9
    },
    // 是否显示总数
    showTotal: {
      type: Boolean,
      default: true
    },
    // 列表总数
    total: {
      type: Number,
      default: 0
    },
    // 列表显示条数
    pageSize: {
      type: Number,
      default: 16
    },
    // 列表显示初始索引
    currentPage: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      activeIndex: 1
    }
  },
  methods: {
    // 点击页数
    changeSize(size) {
      if(size == this.activeIndex){
        return
      }
      this.activeIndex = size;
      this.$emit('size-change', (this.activeIndex - 1) * this.pageSize)
    },
    // 下一页
    nextPage () {
      if (this.pageSize == 1 || this.activeIndex == this.pageNum) {
        return;
      }
      this.activeIndex++;
      this.$emit('current-change', (this.activeIndex - 1) * this.pageSize)
    },
  },
  computed: {
    pageNum() {
      return this.total == 0 ? 1 : Math.ceil(this.total / this.pageSize)
    }
  },
  watch: {
    currentPage: {
      handler(val) {
        this.activeIndex = Math.ceil(val / this.pageSize) == 0 ? 1 : Math.ceil(val / this.pageSize) + 1;
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scope>
.medic-pagin {
  .total {
    margin-right: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    .size {
      color: #333333;
      padding: 0 5px;
    }
  }
  .page {
    margin-right: 20px;
    .item {
      cursor: pointer;
      width: 34px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      background: #ffffff;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 600;
      color: #999999;
      &.act {
        background: #d7000f;
        color: #ffffff;
      }
    }
    .item + .item {
      margin-left: 10px;
    }
  }
  .next {
    cursor: pointer;
    width: 62px;
    height: 34px;
    line-height: 34px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    color: #333333;
    background: #ffffff;
    font-weight: 400;
  }
  .el-pagination.is-background .btn-prev, .el-pagination.is-background .btn-next, .el-pagination.is-background .el-pager li{
    background-color: #ffffff;
    width: 36px;
    height: 36px;
    line-height: 36px;
  }
  .el-pagination span:not([class*=suffix]), .el-pagination button{
    height: 36px;
    line-height: 36px;
  }
  .el-pagination__editor.el-input,
  .el-pagination__editor.el-input .el-input__inner{
    width: 36px;
    height: 36px;
  }
  .el-pagination__editor{
    margin: 0 10px;
  }
}
</style>