<template>
  <div class="empty flex-column-center-center">
    <img src="../../../assets/medicine/kong_icon@2x.png" alt="">
    <p v-if="emptyType !== 'collection'" class="info">很抱歉，没有找到<span v-if="searchText">与“<span class="tip">{{searchText}}</span>”</span>相关中药</p>
    <p v-else class="info">您还没有收藏任何药品，快去浏览吧</p>
  </div>
</template>

<script>
export default {
  props: {
    emptyType: {
      type: String,
      default: ''
    },
    searchText: [String]
  }
}
</script>

<style lang="scss" scope>
.empty{
  padding: 144px 0;
  .info{
    margin-top: 12px;
    font-size: 20px;
    color: #333333;
    .tip{
      color: #D7000F;
    }
  }
}
</style>