<!--
 * @Author: your name
 * @Date: 2020-11-10 09:41:07
 * @LastEditTime: 2020-12-02 15:53:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \yxj-web\src\views\herbalMedicine\components\MedicTabs.vue
-->
<template>
  <el-tabs
    class="medic-tabs"
    v-model="activeName"
    @tab-click="handleClick"
  >
    <el-tab-pane
      v-for="item in selectList"
      :key="item.key"
      :label="item.name"
      :name="item.key"
    ></el-tab-pane>
  </el-tabs>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    selectTabList: {
      type: [Array, Object]
    },
    customIndex: {
      type: String,
      default: '-1'
    }
  },
  data () {
    return {
      selectList: [],
      activeName: '-1',
    }
  },
  methods: {
    handleClick (e) {
      this.$emit('getTabIndex', this.activeName)
    }
  },
  computed: {
    ...mapState({
      medicType: state => state.medic.medicType,
      medicTabSelect: state => state.medic.medicTabSelect,
      collectMedicTabSelect: state => state.medic.collectMedicTabSelect,
    })
  },
  watch: {
    customIndex: {
      handler(val) {
        this.activeName = val; 
      },
      immediate: true
    },
    medicTabSelect: {
      handler(val) {
        if(this.selectTabList) {
          return
        }
        this.selectList = val;
      },
      immediate: true
    },
    selectTabList: {
      handler(val) {
        if(val) {
          this.selectList = val;
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scope>
.medic-tabs {
  .el-tabs__header {
    margin: 0;
  }
  .el-tabs__nav-wrap::after {
    display: none;
  }
  .el-tabs__item {
    padding: 0 6px !important;
    padding-right: 27px !important;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    &:hover{
      color: #D7000F!important;
    }
    &.is-active {
      font-weight: bold;
      color: #333333;
    }
  }
  .el-tabs__active-bar {
    background-color: #d7000f;
  }
}
</style>