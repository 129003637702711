<template>
  <ul class="search-list flex-start-center flex-wrape">
    <li class="item" v-for="(item,index) in list" :key="index" @click="goInfo(item)">
      <div class="top flex-start-start">
        <img class="avar" v-for="(child,childIndex) in item.pictureList" :key="childIndex" :src="child.picUrl" v-if="child.ifCover == 1 && item.drugType == 1" alt="">
        <div class="name-box">
          <h1 class="name" :class="{one: item.drugType == 1}" v-if="item.drugName">
            <span class="text">{{drugNameEllips(item)}}</span>
            <br v-if="item.drugType == 2 && strlen(item.drugName) < 16" />
            <!-- <span class="tag error">有毒</span> -->
            <Tag :productItem="item"/>
          </h1>
          <h3 class="en-name" v-if="item.drugPinyinName && item.drugType == 1">{{item.drugPinyinName}}</h3>
        </div>
      </div>
      <div class="bottom">
        <p class="info">
          <span v-if="item.indications">功能主治：{{item.indications | removeHtml | ellipsisInfo}}</span>
          <span v-if="item.companyName">生产企业：{{item.companyName | removeHtml | ellipsisInfo}}</span>
        </p>
        <p class="toxicDrug ellipsis" v-if="item.toxicDrug && item.drugType == 2">有毒草药：{{item.toxicDrug}}</p>
        <div class="colloge" :class="{active: item.isCollect}" @click.stop="poisonDrugCollect(item)">{{item.isCollect ? '取消' : '收藏'}}</div>
      </div>
    </li>
    <div class="flex-center-center flex-1" v-noCopy>
      <Empty :emptyType="emptyType" :searchText="searchText" v-if="isEmpty" />
    </div>
  </ul>
</template>

<script>
import Tag from '@/views/herbalMedicine/components/tag'
import Empty from '@/views/herbalMedicine/components/Empty'
import { poisonDrugCollect } from '@/api/herbalMedicine'
import { mapState, mapMutations } from 'vuex'
import { strlen } from '@/utils'
import eventBus from '@/eventBus'
import { removeHtml } from '@/utils/filters'
export default {
  props: {
    // 搜索的内容
    searchText: [String],
    isEmpty: {
      type: Boolean,
      default: false
    },
    // 展示列表
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 是否为空
    showEmpty: {
      type: Boolean,
      default: false
    },
    // 为空显示的文案
    emptyType: {
      type: String,
      default: ''
    }
  },
  mounted() {
    eventBus.$off('collect'); //先解绑，不然会多次出发
    eventBus.$on('collect', obj => {
      this.poisonDrugCollect(obj)
    })
  },
  methods: {
    goInfo(item) {
      // drugType 药品类型【1-中草药,2-中成药】
      let path = item.drugType == 1 ? '/medicine/herbalMedicine/productInfo' : '/medicine/patentMedicine/productInfo';
      this.$router.push({
        path: path+'/'+item.drugId,
      })
      eventBus.$emit('changeCollection', 'close')
    },
    // 收藏
    poisonDrugCollect(item) {
      const parmary = {
        drugId: item.drugId,  // 药品ID
        option: item.isCollect ? 0 : 1,  // 操作类型【0:取消收藏,1:收藏】
        drugName: item.drugName ? item.drugName : '',  // 药品名称
        drugType: item.drugType ? item.drugType: '',  // 药品类型【1-中草药,2-中成药】
      }
      if(!this.userInfo.nickName) {
        eventBus.$emit('goLogin', parmary)
        return;
      }
      poisonDrugCollect(parmary).then(res => {
        // this.$notify({
        //   title: '成功',
        //   message: item.isCollect ? '取消收藏成功' : '收藏成功',
        //   type: 'success',
        //   customClass: 'notify-success'
        // });
        // 通知父组件刷新
        this.$emit('reload')
        eventBus.$emit('reload')
      }).catch(() => {
        this.$notify({
          title: '失败',
          message: item.isCollect ? '取消收藏失败' : '收藏失败',
          type: 'error'
        });
      })
    },
    strlen(val) {
      return strlen(val)
    },
    drugNameEllips(item) {
      let str = item.drugName;
      if(str && str.length > 21) {
        str = str.substring(0, 21) + '...'
      }
      return str
    },
    removeHtml(val) {
      return removeHtml(val)
    }
  },
  filters: {
    ellipsisTitle(val) {
      if(val && val.length > 21) {
        val = val.substring(0, 21) + '...'
      }
      return val
    },
    ellipsisInfo(val) {
      if(val && val.length > 30 * 2) {
        val = val.substring(0, 30 * 2) + '...'
      }
      return val
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  components: {
    Empty,
    Tag
  },
  beforeDestroy () {
    eventBus.$off('collect'); //先解绑，不然会多次出发
  },
  watch: {
    // list(val) {
    //   if(val.length == 0) {
    //     this.showEmpty = true;
    //   }
    // }
  }
}
</script>

<style lang="scss" scope>
.search-list{
  cursor: pointer;
  list-style: none;
  .item{
    position: relative;
    // cursor: pointer;
    margin-right: 16px;
    margin-bottom: 16px;
    padding: 16px 10px;
    width: 284px;
    height: 145px;
    background: #FFFFFF;
    border-radius: 2px;
    box-sizing: border-box;
    &:hover{
      .colloge{
        display: block;
      }
      // opacity: 0.5;
    }
    &:nth-child(4n) {
      margin-right: 0;
    }
    .top{
      height: 50px;
      margin-bottom: 10px;
      .avar{
        margin-right: 10px;
        width: 50px;
        height: 50px;
        border-radius: 2px;
      }
      .name-box{
        width: 100%;
        .name{
          // display: flex;
          // flex-direction: row;
          // justify-items: flex-start;
          // align-items: center;
          // flex-wrap: wrap;
          width: 100%;
          margin: 0;
          margin-bottom: 2px;
          font-size: 16px;
          font-weight: 600;
          color: #333333;
          line-height: 22px;
          vertical-align: middle;
          .text{
            margin-right: 5px;
            vertical-align: middle;
          }
          &.one{
            padding-top: 3px;
            width: 210px;
            word-wrap: break-word;
            .text{
              display: inline-block;
              max-width: 162px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .medic-tag{
            vertical-align: middle;
          }
          &>.tag{
            vertical-align: middle;
            display: inline-block;
            margin-left: 5px;
            width: 40px;
            height: 15px;
            line-height: 15px;
            border-radius: 1px;
            text-align: center;
            font-weight: 600;
            font-size: 10px;
            &.green{
              border: 1px soldi #7CBE21;
              color: #7CBE21;
            }
            &.error{
              border-radius: 2px;
              border: 1px solid #D7000F;
              color: #D7000F;
            }
            &.wraning{
              border: 1px solid #EEA4A6;
              color: #EEA4A6;
            }
          }
        }
        .en-name{
          font-size: 12px;
          font-weight: 600;
          color: #333333;
          line-height: 17px;
        }
      }
    }
    .bottom{
      .info{
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        line-height: 17px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .toxicDrug{
        margin-top: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #E93F35;
        line-height: 17px;
      }
    }
    .colloge{
      display: none;
      position: absolute;
      cursor: pointer;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 24px;
      line-height: 24px;
      padding-left: 119px;
      border-radius: 1px;
      color: #666666;
      font-size: 14px;
      box-sizing: border-box;
      background-image: url('./../../../assets/medicine/line_star@2x.png');
      background-repeat: no-repeat!important;
      background-size: 11.97px 11.48px!important;
      background-position: 150px center!important;
      border-radius: 0px 0px 2px 2px;
      background-color: #E8E8E8;
      &:hover{
        color: #D7000F!important;
      }
      &.active{
        border-color: #FBE5E7;
        color: #D7000F;
        background-image: url('./../../../assets/medicine/red_M_star@2x.png');
        &:hover{
          color: rgba(356,100,84,0.7)!important;
          background-image: url('./../../../assets/medicine/red_M_star_hover@2x.png');
        }
      }
    }
  }
}
</style>